<template>
    <section class="favorite">
        <!--工具条-->
        <FullCalendar :locale="locale"
                      :first-day="1"
                      ref="fullCalendar"
                      defaultView="timeGridDay"
                      :plugins="calendarPlugins"
                      :now-indicator="true"
                      :all-day-slot="false"
                      min-time="00:00:00"
                      max-time="24:00:00"
                      slot-duration="00:30:00"
                      slot-label-interval="01:00:00"
                      height='auto'
                      :content-height="content_height"
                      :nav-links="true"
                      event-class-name="course-inner-text"
                      :events="loadEvents"
                      :event-time-format="eventTimeFormat"
                      :slot-label-format="slotLabelFormat"
                      @windowResize="handleResize"
                      scroll-time="08:00"
                      @eventClick="handleEventClick"
                      :header="{left:'today prev,next',center:'title',right:'timeGridDay,timeGridWeek,dayGridMonth,listWeek'}"
        />

        <lesson-view :single="single.course" :view_mode.sync="view_mode"></lesson-view>

    </section>

</template>

<script>
    import FullCalendar from '@fullcalendar/vue'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import timeGridPlugin from '@fullcalendar/timegrid'
    import listPlugin from '@fullcalendar/list'
    import zhLocale from '@fullcalendar/core/locales/zh-cn';
    import LessonView from "@/components/lesson-view";

    export default {
        name: "dashboard",
        components: {
            FullCalendar // make the <FullCalendar> tag available
            ,LessonView
        },
        data() {
            return {
                calendarPlugins: [dayGridPlugin, timeGridPlugin, listPlugin],
                locale: zhLocale,
                slotLabelFormat: {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                },
                eventTimeFormat: {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                },
                data: [],
                single: {},
                view_mode: false,
                content_height:100,
                screen_height:100,
            }
        },
        computed: {
            course() {
                return this.single.course ? this.single.course : {};
            },
        },
        methods: {
            reload() {
                const month = 1;
                this.$api('User.Calendar.My', {month: month}, 1).then((res) => {
                    this.evnets = res.data;
                });
            },
            handleResize(arg){
                this.screen_height =  document.body.clientHeight;
                this.content_height = this.screen_height - 200;
                arg.updateSize();
            },
            loadEvents(fetchInfo, successCallback) {
                this.$api('User.Calendar.My', {
                    start: fetchInfo.start.Format('yyyy-MM-dd'),
                    end: fetchInfo.end.Format('yyyy-MM-dd')
                }, 1).then((res) => {
                    this.data = res.data.data;
                    successCallback(res.data.data);
                });
            },
            handleEventClick(eventInfo) {
                const id = eventInfo.event.id;
                this.$api('User.Calendar.get', {id: id}, 1).then((res) => {
                    this.single = res.data.data;
                    this.view_mode = true;
                });
            },
            removeFavorite() {
                this.$api('User.Calendar.Remove', this.single, 1).then(() => {
                    this.view_mode = false;
                    this.$notice.success(this.course.course_name + ' 已经从收藏中移除!');
                    let api = this.$refs.fullCalendar.getApi();
                    const src = api.getEventSources();
                    src.forEach(function (x) {
                        x.refetch();
                    });
                });
            }
        },
        mounted() {
            this.screen_height =  document.body.clientHeight;
            this.content_height = this.screen_height - 220;
        },
    }
</script>

<style lang="scss">
    @import '~@fullcalendar/core/main.css';
    @import '~@fullcalendar/daygrid/main.css';
    @import '~@fullcalendar/timegrid/main.css';
    @import "~@fullcalendar/list/main.css";

    .favorite {
        .course-inner-text {
            color: white;
            font-size: 14px;
            background-color: #009688;
            transition: all 0.4s ease;

            .fc-time {
                color: white;
                font-size: 13px;
                font-weight: bold;
            }

            &:hover {
                color: whitesmoke;
                cursor: pointer;
                background-color: #0288d1;
                border-color: #0288d1;
                box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.5);
                transition: all 0.4s ease;

            }

            &.fc-list-item {
                background-color: transparent;
                color: black;

                &:hover {
                    box-shadow: none;
                }
            }
        }


    }

</style>
