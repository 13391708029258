<template>
    <section class="">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :model="model"
                     @reload="handleReload"
                     btn_review
                     @review="handleReview"
                     :btn_edit="false"
                     :btn_add="false"
                     :btn_delete="false"
        >
            <div class="pull-right" style="margin-top:4px; ">
                <el-cascader
                    class="mr-3"
                    v-model="search.area"
                    placeholder="请选择地区"
                    size="medium"
                    :options="areas"
                    :props="{checkStrictly:true}"
                    @change="handleAreaChange"
                    style="width: 200px;"
                    clearable
                    filterable>
                </el-cascader>
                <el-switch @change="handleChange" v-model="search.use_date_range"
                           style="margin: 5px 10px 5px 0"></el-switch>
                <el-select  class="mr-3" v-if="!search.use_date_range"
                            clearable filterable size="medium"
                            v-model="search.extra['S']"
                            @change="handleChangeSemesterForExtra"
                            placeholder="请选择学期">
                    <el-option v-for="(x,id) in semester_options" :key="id" :value="id"
                               :label="x.name+' ('+x.start_date+' - '+x.end_date+')'">
                        <span class="d-inline-block" style="width:200px;">{{ x.name }}</span>
                        <span class="text-success">{{ x.start_date }} - {{ x.end_date }}</span>
                    </el-option>
                </el-select>
                <template v-else>
                    <el-button size="small" icon="el-icon-arrow-left" :disabled="!search.use_date_range" circle
                               class="mr-2"
                               @click="goDateChangeBack"></el-button>
                    <el-date-picker size="small"
                                    :picker-options="{'firstDayOfWeek': 1}"
                                    @change="handleChange"
                                    :disabled="!search.use_date_range"
                                    v-model="search.date_range"
                                    type="daterange"
                                    range-separator="-"
                                    start-placeholder="开始"
                                    value-format="yyyy-MM-dd"
                                    end-placeholder="结束">
                    </el-date-picker>
                    <el-button size="small" icon="el-icon-arrow-right" :disabled="!search.use_date_range" circle
                               class="ml-2" @click="goDateChangeForward"></el-button>
                </template>
            </div>

        </front-table>
        <!--编辑界面-->
        <div class="clearfix"></div>
        <lesson-view :single="single.course" :view_mode.sync="view_mode"></lesson-view>

    </section>
</template>

<script>
import Calendar from "@/common/mixins/calendar-mixin.js";
import FrontTable from "@/components/front-table";
import LessonView from "@/components/lesson-view";

export default {
    mixins: [Calendar],
    components: {LessonView, FrontTable},
    created() {
        this.loadArea(2, true);
    }
}
</script>

<style lang="scss">

</style>
