<template>
    <section class="">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :model="model"
                     @reload="handleReload"
                     :btn_edit="false"
                     :btn_add="false"
                     :btn_delete="false"
                     :btn_review="true"
                     @review="handleReview"

        >
        </front-table>
        <!--编辑界面-->
        <el-dialog :visible.sync="view_mode" width="75%" @open="cleanData" v-mobile-dialog>
            <div slot="title" class="text-primary">
                ({{single.meeting_code}}) |
                {{single.course_number == single.course_name?'':(single.course_number+' | ')}}{{single.course_name}}
            </div>
            <div>
                <meeting-info :single="single"></meeting-info>
                <div class="line"></div>
                <div class="">
                    <!--
                    <el-button size="medium" type="primary" icon="el-icon-question" onload="process.loading"
                               @click="searchMeetings" :loading="process.loading">点击查询课程
                    </el-button>
                    -->
                    <el-select ref="meetings" v-model="uuid" placeholder="请选择" size="medium" class="mr-3 mb-3" filterable
                               no-data-text="未找到会议">
                        <el-option
                                v-for="item in meetings"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button size="medium" type="info" icon="el-icon-download" onload="process.loading" class="mb-3"
                               @click="downloadReport" :loading="process.loading">下载报告
                    </el-button>
                </div>

                <div v-show="show_table" ref="excel_table" style="height: 1px;overflow-y: hidden">
                    <div class="line"></div>
                    <el-table border size="small"
                              :data="table.data"
                              height="250"
                              style="width: 100%">
                        <el-table-column v-for="header in table.headers" :key="header.prop"
                                         :prop="header.prop"
                                         :label="header.label">
                        </el-table-column>
                    </el-table>
                </div>

            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="" @click="view_mode = false">确 定</el-button>
            </div>
        </el-dialog>
        <div class="clearfix"></div>
    </section>
</template>

<script>
    import FrontMixins from "../../common/mixins/front-mixins";
    import Statistics from "../../common/mixins/statistics-mixin";
    import FrontTable from "../../components/front-mobile-table"
    import MeetingInfo from "../../components/meeting-info";
    export default {
        mixins: [FrontMixins, Statistics],
        components: {FrontTable, MeetingInfo},
    }

</script>

<style lang="scss">

</style>
